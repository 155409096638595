import React, { useEffect } from 'react'
import styled from 'styled-components'
import { withRouter } from 'next/router'
import { EventTrackingConsumer } from '../EventTrackingProvider/EventTrackingProvider'
import GlobalContext from '../GlobalContext'
import { generateQuery } from '../CategoryCard/CategoryCardUtils'
import Link from 'next/link'
import { SCREEN_SIZE } from '../../lib/Media'
import Text from '../Text'
import defaultTheme from '@ntuctech/devex-tangram/Theme/defaultTheme'
import { AutoSizer, Grid } from 'react-virtualized'
import {
  GTM_ENV_VAR as GEV,
  getNCheckFn as getNCheck,
  SPLIT_EVENT,
} from '../../lib/eventTrackerFunction'
import { usePromoImpressionTracker } from '../Recipe/recipeUtils'
import { CheckoutAddressConsumer } from '../CheckoutAddressProvider/CheckoutAddressProvider'
import {
  useCheckoutAddressStoreIdUpdate,
  useTrackPromoImpression,
} from './trackingUtils'
import { Router } from '../../routes'
import { AMP_EVENT_NAME as AEN } from '../../lib/amplitude'
import { IdleQueue } from '../../lib/idlize/IdleQueue'
import SvgBubblePlaceholder from '../icons/BubblePlaceholder'

const Image = styled.img`
  object-fit: cover;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  border: 1px #eaeaea solid;
`

const PlaceholderImage = styled.div`
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  background-color: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px #eaeaea solid;
`

const DefaultImage = () => {
  return (
    <PlaceholderImage data-testid="default-image">
      <SvgBubblePlaceholder width={28} height={28} />
    </PlaceholderImage>
  )
}

const CardImage = ({ collection }) => {
  const [isBrokenImage, setIsBrokenImage] = React.useState(false)

  const imageSrc =
    collection.category &&
    typeof collection.category.image === 'string' &&
    `${collection.category.image}?q=60&h=200`

  return (
    <>
      {collection.category.image ? (
        <div>
          {!isBrokenImage ? (
            <Image
              src={imageSrc}
              alt={collection.category.name}
              onError={() => {
                setIsBrokenImage(true)
              }}
            />
          ) : (
            <DefaultImage />
          )}
        </div>
      ) : (
        <DefaultImage />
      )}
    </>
  )
}

const CardLink = styled.a`
  display: flex;
  width: 4.5rem;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  justify-content: center;
`

const CardTitle = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
  color: #333333;
  margin-top: 0.25rem;
`

const TruncateTextContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: ${({ lineClamp }) => lineClamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  word-wrap: break-word;
`

const queue = new IdleQueue()

const createImpressionTrackingData = ({
  checkoutAddress,
  position,
  collection,
  asPath,
  title,
}) => {
  const { id, image } = collection.category
  return {
    refId: `${checkoutAddress?.clientId}-${id}`,
    [GEV.ECOMM_ID]: title || '',
    [GEV.ECOMM_NAME]: image || '',
    [GEV.ECOMM_CREATIVE]: asPath || '',
    [GEV.ECOMM_POSITION]: position,
  }
}

const BubbleCategoryCardInner = props => {
  const {
    collection,
    createObserver: createObserverInfo,
    trackImpressions,
    position,
    checkoutAddress,
    title,
    router,
    ctaLocation,
    ctaSublocation,
    track,
    trackSplit,
  } = props
  const { href, asPath } = generateQuery(props)

  const elementRef = React.useRef()

  const impressionTrackingData = createImpressionTrackingData({
    checkoutAddress,
    position,
    collection,
    title,
  })

  useTrackPromoImpression({
    queue,
    elementRef,
    checkoutAddress,
    createObserverInfo,
    impressionTrackingData,
  })

  const handleClick = e => {
    e.preventDefault()
    if (trackImpressions) {
      trackImpressions({ action: 'abandon' })
    }
    if (router?.pathname !== '/') {
      track({
        [GEV.AMPLITUDE_EVENT_NAME]: AEN.CATEGORY_CLICKED,
        [GEV.AMPLITUDE_CTA_LOCATION]: ctaLocation,
        [GEV.AMPLITUDE_CTA_SUBLOCATION]: ctaSublocation,
        [GEV.AMPLITUDE_CATEGORY_NAME]: collection?.category?.name,
        [GEV.AMPLITUDE_CATEGORY_LEVEL]: 3,
      })
    } else {
      track({
        [GEV.EVENT]: 'promotionClick',
        [GEV.ECOMMERCE]: {
          promoClick: {
            promotions: [impressionTrackingData],
          },
        },
        [GEV.AMPLITUDE_EVENT_NAME]: AEN.BANNER_CLICKED,
        [GEV.AMPLITUDE_CTA_LOCATION]: ctaLocation,
        [GEV.AMPLITUDE_CTA_SUBLOCATION]: ctaSublocation,
      })

      // track click on category bubble
      track({
        [GEV.EVENT_CAT]: 'browsing-home_main',
        [GEV.EVENT_ACTION]: 'click',
        [GEV.EVENT_LABEL]: `category-bubble-${collection?.category?.name}`,
        [GEV.EVENT]: 'hm_push_event',
        [GEV.PAGE_TYPE]: 'home',
        [GEV.SCREEN_NAME]: 'grocery-online | home',
      })

      trackSplit([
        {
          event_type: SPLIT_EVENT,
          [GEV.EVENT]: 'splitPromoClick',
          ...impressionTrackingData,
        },
      ])
    }

    if (asPath === '/tag/dc-redirect') {
      Router.push('membership/registration')
    } else if (asPath === '/tag/recipes-redirect') {
      Router.push('recipes')
    } else {
      Router.push(href, asPath)
    }
  }
  return (
    <Link href={href} as={asPath} passHref prefetch={false} legacyBehavior>
      <CardLink onClick={handleClick}>
        <CardImage collection={collection} />
        <CardTitle>
          <TruncateTextContainer lineClamp={2}>
            {collection.category.name}
          </TruncateTextContainer>
        </CardTitle>
      </CardLink>
    </Link>
  )
}

const BubbleCategoryCard = withRouter(props => (
  <GlobalContext.Consumer>
    {({ remoteConfig }) => (
      <EventTrackingConsumer>
        {({ track, trackSplit }) => (
          <BubbleCategoryCardInner
            {...props}
            track={track}
            trackSplit={trackSplit}
            remoteConfig={remoteConfig}
          />
        )}
      </EventTrackingConsumer>
    )}
  </GlobalContext.Consumer>
))

const CollectionHeaderContainer = styled.div`
  margin-bottom: 1rem;
`

const CollectionTitle = styled.h2`
  font-weight: 700;
  color: #333333;
  font-size: 1.25rem;
  line-height: 1.4rem;
  ${SCREEN_SIZE.Below.Tablet} {
    font-size: 1rem;
  }
`

const CollectionSubtitle = styled(Text)`
  font-size: 1rem;
  ${SCREEN_SIZE.Below.Tablet} {
    font-size: 0.875rem;
  }
`

const CollectionHeader = ({ title, subtitle }) => {
  if (!title && !subtitle) {
    return null
  }

  return (
    <CollectionHeaderContainer>
      {title && (
        <CollectionTitle
          size="large"
          weight="bold"
          color={defaultTheme.ColorBaseMineShaft700}
        >
          {title}
        </CollectionTitle>
      )}
      {subtitle && (
        <CollectionSubtitle
          as="h3"
          color={defaultTheme.ColorBaseMineShaft500}
          size="large"
        >
          {subtitle}
        </CollectionSubtitle>
      )}
    </CollectionHeaderContainer>
  )
}

const CollectionContainer = styled.div`
  margin: 0.5rem 0 1.8125rem 0;
`

const VIRTUAL_GRID_CLASS = '.ReactVirtualized__Grid'

const ScrollContainer = styled.div`
  overflow-x: scroll;
  height: 7rem;

  ${VIRTUAL_GRID_CLASS} {
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none; /*To hide scrollbar in firefox */
    -webkit-overflow-scrolling: touch;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  scrollbar-width: none; /*To hide scrollbar in firefox */
  -webkit-overflow-scrolling: touch;
`

const Collection = ({ data, config, createObserver, trackImpressions }) => {
  const title = data?.title
  const { subtitle } = config || {}

  const columnCount = data.collection.length

  const [gap, setGap] = React.useState(
    window.matchMedia('(min-width: 768px)').matches ? 32 : 16
  )

  useEffect(() => {
    const handleMediaQueryChange = e => {
      setGap(e.matches ? 32 : 16)
    }

    const mediaQueryList = window.matchMedia('(min-width: 768px)')
    mediaQueryList.addEventListener('change', handleMediaQueryChange)

    return () => {
      mediaQueryList.removeEventListener('change', handleMediaQueryChange)
    }
  }, [])
  return (
    <CollectionContainer data-testid="bubble-category-collection">
      <CollectionHeader title={title} subtitle={subtitle} />
      <ScrollContainer>
        <AutoSizer disableHeight>
          {({ width }) => (
            <Grid
              key={gap}
              width={width}
              height={112}
              columnCount={columnCount}
              columnWidth={({ index }) => {
                return index === columnCount - 1 ? 72 : 72 + gap
              }}
              rowCount={1}
              rowHeight={112}
              cellRenderer={({ key, columnIndex, style }) => {
                return (
                  <div key={key} style={style}>
                    <BubbleCategoryCard
                      title={title}
                      collection={data.collection[columnIndex]}
                      position={columnIndex + 1}
                      createObserver={createObserver}
                      trackImpressions={trackImpressions}
                      ctaLocation={config?.ctaLocation}
                      ctaSublocation={config?.ctaSublocation}
                    />
                  </div>
                )
              }}
              overscanColumnCount={10}
            />
          )}
        </AutoSizer>
      </ScrollContainer>
    </CollectionContainer>
  )
}

const PlaceholderCollection = styled.div`
  display: flex;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /*To hide scrollbar in firefox */
  -webkit-overflow-scrolling: touch;
`

const BubbleCategoryCollectionInner = props => {
  const [isClient, setIsClient] = React.useState(false)

  const { config, data, track, trackSplit, checkoutAddress } = props

  const { createObserver, trackImpressions } = usePromoImpressionTracker({
    track,
    trackSplit,
    checkoutAddress,
    meta: {},
  })

  useCheckoutAddressStoreIdUpdate(prevCheckoutAddress => {
    trackImpressions({
      action: 'trackOnUpdate',
      prevStoreId: getNCheck(prevCheckoutAddress, 'clientId', '').toString(),
      prevDBPStoreId: getNCheck(prevCheckoutAddress, 'storeId', '').toString(),
    })
  })

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) {
    return (
      <PlaceholderCollection>
        {data.collection.slice(0, 5).map((item, index) => (
          <div key={item.id}>
            <BubbleCategoryCard
              title={data?.title}
              collection={item}
              position={index + 1}
              createObserver={createObserver}
              trackImpressions={trackImpressions}
              ctaLocation={config?.ctaLocation}
              ctaSublocation={config?.ctaSublocation}
            />
          </div>
        ))}
      </PlaceholderCollection>
    )
  }
  return <Collection data={data} config={config} />
}

const BubbleCategoryCollection = props => (
  <CheckoutAddressConsumer>
    {({ checkoutAddress }) => (
      <EventTrackingConsumer>
        {({ track, trackSplit }) => (
          <React.Fragment>
            <div />
            {/* Needed for avoiding layout shifting in the home page */}
            <BubbleCategoryCollectionInner
              {...props}
              track={track}
              trackSplit={trackSplit}
              checkoutAddress={checkoutAddress}
            />
          </React.Fragment>
        )}
      </EventTrackingConsumer>
    )}
  </CheckoutAddressConsumer>
)

BubbleCategoryCollection.defaultProps = {
  config: {},
}

export default BubbleCategoryCollection
