import * as React from 'react'
function SvgBubblePlaceholder(props) {
  return (
    <svg
      width={76}
      height={60}
      viewBox="0 0 76 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.443A3.449 3.449 0 013.455 0h69.09A3.449 3.449 0 0176 3.443v53.114A3.449 3.449 0 0172.546 60H3.455A3.449 3.449 0 010 56.557V3.443zm6.91 3.442v46.23h62.18V6.885H6.91z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.25 19.625l25.625 10.64-2.658 6.355-21.752-9.031-24.411 20.355L1.255 34.47l3.413-5.987 19.63 11.115L48.25 19.625z"
        fill="#fff"
      />
      <path
        d="M30.104 18.689c0 4.074-3.314 7.377-7.403 7.377-4.088 0-7.402-3.303-7.402-7.377 0-4.075 3.314-7.377 7.402-7.377 4.089 0 7.403 3.302 7.403 7.377z"
        fill="#fff"
      />
    </svg>
  )
}
export default SvgBubblePlaceholder
