import isEmpty from 'lodash/isEmpty'
import {
  mapFiltersFromUrl,
  convertFilters,
  generateQueryString,
} from '../../lib/filterFormatter'

const isEnabledNavCategoryFilter = props => {
  const navCategoryEnableList = ['search', 'category']
  const currentPathName = props.router?.pathname
    ?.substring(1)
    .replace(/Details$/g, '')
  return navCategoryEnableList.includes(currentPathName)
}

const generateQuery = props => {
  const { collection } = props
  const route =
    collection.category.parentCategory === undefined ? 'tag' : 'category'
  const nameToTrack = collection.category.name
    ? encodeURIComponent(collection.category.name)
    : ''
  const queryStringObject = {
    exclude_ga_track_name: nameToTrack,
    exclude_ga_track_id: collection?.category?.id || '',
  }

  if (isEnabledNavCategoryFilter(props)) {
    const appliedFilters = mapFiltersFromUrl(props.router?.query?.filter) || {}
    if (!isEmpty(appliedFilters)) {
      queryStringObject.filter = convertFilters(appliedFilters)
    }
    if (props.router?.query?.query) {
      queryStringObject.query = encodeURIComponent(props.router?.query?.query)
    }
  }

  const asPath = collection.category.slug
    ? `/${route}/${collection.category.slug}` +
      generateQueryString(queryStringObject)
    : '#'

  queryStringObject.slug = collection.category.slug
  const href = collection.category.slug
    ? `/${route}Details` + generateQueryString(queryStringObject)
    : '#'

  return { href, asPath }
}

export { isEnabledNavCategoryFilter, generateQuery }
